.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  margin-left: -8px;
  position: absolute;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  box-sizing: content-box;
  position: absolute;
  border: 8px solid transparent;
  height: 0;
  width: 1px;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  content: "";
  z-index: -1;
  border-width: 8px;
  left: -8px;
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  top: 0;
  margin-top: -8px;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-top: none;
  border-bottom-color: #f0f0f0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  top: -1px;
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  bottom: 0;
  margin-bottom: -8px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  border-bottom: none;
  border-top-color: #fff;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  bottom: -1px;
  border-top-color: #aeaeae;
}

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
}

.react-datepicker {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
}

.react-datepicker--time-only .react-datepicker__triangle {
  left: 35px;
}

.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0;
}

.react-datepicker--time-only .react-datepicker__time {
  border-radius: 0.3rem;
}

.react-datepicker--time-only .react-datepicker__time-box {
  border-radius: 0.3rem;
}

.react-datepicker__triangle {
  position: absolute;
  left: 50px;
}

.react-datepicker-popper {
  z-index: 1;
}

.react-datepicker-popper[data-placement^="bottom"] {
  margin-top: 10px;
}

.react-datepicker-popper[data-placement="bottom-end"] .react-datepicker__triangle, .react-datepicker-popper[data-placement="top-end"] .react-datepicker__triangle {
  left: auto;
  right: 50px;
}

.react-datepicker-popper[data-placement^="top"] {
  margin-bottom: 10px;
}

.react-datepicker-popper[data-placement^="right"] {
  margin-left: 8px;
}

.react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle {
  left: auto;
  right: 42px;
}

.react-datepicker-popper[data-placement^="left"] {
  margin-right: 8px;
}

.react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle {
  left: 42px;
  right: auto;
}

.react-datepicker__header {
  text-align: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  padding-top: 8px;
  position: relative;
}

.react-datepicker__header--time {
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 2px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #000;
  font-weight: bold;
  font-size: 0.944rem;
}

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-datepicker__navigation {
  background: none;
  line-height: 1.7rem;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 10px;
  width: 0;
  padding: 0;
  border: 0.45rem solid transparent;
  z-index: 1;
  height: 10px;
  width: 10px;
  text-indent: -999em;
  overflow: hidden;
}

.react-datepicker__navigation--previous {
  left: 10px;
  border-right-color: #ccc;
}

.react-datepicker__navigation--previous:hover {
  border-right-color: #b3b3b3;
}

.react-datepicker__navigation--previous--disabled, .react-datepicker__navigation--previous--disabled:hover {
  border-right-color: #e6e6e6;
  cursor: default;
}

.react-datepicker__navigation--next {
  right: 10px;
  border-left-color: #ccc;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 80px;
}

.react-datepicker__navigation--next:hover {
  border-left-color: #b3b3b3;
}

.react-datepicker__navigation--next--disabled, .react-datepicker__navigation--next--disabled:hover {
  border-left-color: #e6e6e6;
  cursor: default;
}

.react-datepicker__navigation--years {
  position: relative;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.react-datepicker__navigation--years-previous {
  top: 4px;
  border-top-color: #ccc;
}

.react-datepicker__navigation--years-previous:hover {
  border-top-color: #b3b3b3;
}

.react-datepicker__navigation--years-upcoming {
  top: -4px;
  border-bottom-color: #ccc;
}

.react-datepicker__navigation--years-upcoming:hover {
  border-bottom-color: #b3b3b3;
}

.react-datepicker__month-container {
  float: left;
}

.react-datepicker__month {
  margin: 0.4rem;
  text-align: center;
}

.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  margin: 5px 0 10px 15px;
  text-align: left;
}

.react-datepicker__input-time-container .react-datepicker-time__caption {
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container {
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
  display: inline-block;
  margin-left: 10px;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
  width: 85px;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-inner-spin-button,
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"] {
  -moz-appearance: textfield;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
  margin-left: 5px;
  display: inline-block;
}

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid #aeaeae;
  width: 85px;
}

.react-datepicker__time-container--with-today-button {
  display: inline;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  position: absolute;
  right: -72px;
  top: 0;
}

.react-datepicker__time-container .react-datepicker__time {
  position: relative;
  background: white;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 85px;
  overflow-x: hidden;
  margin: 0 auto;
  text-align: center;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  list-style: none;
  margin: 0;
  height: calc(195px + (1.7rem / 2));
  overflow-y: scroll;
  padding-right: 0px;
  padding-left: 0px;
  width: 100%;
  box-sizing: content-box;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  height: 30px;
  padding: 5px 10px;
  white-space: nowrap;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #216ba5;
  color: white;
  font-weight: bold;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #216ba5;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
  color: #ccc;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__week-number {
  color: #ccc;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #000;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range,
.react-datepicker__quarter--selected,
.react-datepicker__quarter--in-selecting-range,
.react-datepicker__quarter--in-range {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff;
}

.react-datepicker__month--selected:hover, .react-datepicker__month--in-selecting-range:hover, .react-datepicker__month--in-range:hover,
.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter--in-selecting-range:hover,
.react-datepicker__quarter--in-range:hover {
  background-color: #1d5d90;
}

.react-datepicker__month--disabled,
.react-datepicker__quarter--disabled {
  color: #ccc;
  pointer-events: none;
}

.react-datepicker__month--disabled:hover,
.react-datepicker__quarter--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text {
  cursor: pointer;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}

.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today {
  font-weight: bold;
}

.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted,
.react-datepicker__quarter-text--highlighted {
  border-radius: 0.3rem;
  background-color: #3dcc4a;
  color: #fff;
}

.react-datepicker__day--highlighted:hover,
.react-datepicker__month-text--highlighted:hover,
.react-datepicker__quarter-text--highlighted:hover {
  background-color: #32be3f;
}

.react-datepicker__day--highlighted-custom-1,
.react-datepicker__month-text--highlighted-custom-1,
.react-datepicker__quarter-text--highlighted-custom-1 {
  color: magenta;
}

.react-datepicker__day--highlighted-custom-2,
.react-datepicker__month-text--highlighted-custom-2,
.react-datepicker__quarter-text--highlighted-custom-2 {
  color: green;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover {
  background-color: #1d5d90;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: #2a87d0;
  color: #fff;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover {
  background-color: #1d5d90;
}

.react-datepicker__day--in-selecting-range ,
.react-datepicker__month-text--in-selecting-range ,
.react-datepicker__quarter-text--in-selecting-range {
  background-color: rgba(33, 107, 165, 0.5);
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range , .react-datepicker__month--selecting-range
.react-datepicker__month-text--in-range , .react-datepicker__month--selecting-range
.react-datepicker__quarter-text--in-range {
  background-color: #f0f0f0;
  color: #000;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled {
  cursor: default;
  color: #ccc;
}

.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover,
.react-datepicker__quarter-text--disabled:hover {
  background-color: transparent;
}

.react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover, .react-datepicker__month-text.react-datepicker__quarter--selected:hover, .react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__month--selected:hover,
.react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
  background-color: #216ba5;
}

.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
  background-color: #f0f0f0;
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: 0.3rem;
}

.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {
  cursor: pointer;
}

.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  border-top-color: #ccc;
  float: right;
  margin-left: 20px;
  top: 8px;
  position: relative;
  border-width: 0.45rem;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: #f0f0f0;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0.3rem;
  border: 1px solid #aeaeae;
}

.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {
  cursor: pointer;
}

.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background-color: #ccc;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #b3b3b3;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
  position: absolute;
  left: 15px;
}

.react-datepicker__close-icon {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0px 6px 0px 0px;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}

.react-datepicker__close-icon::after {
  cursor: pointer;
  background-color: #216ba5;
  color: #fff;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  padding: 2px;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  content: "\D7";
}

.react-datepicker__today-button {
  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
}

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647;
}

.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem;
}

@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
  }
}

.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
  font-size: 1.44rem;
}

.react-datepicker__portal .react-datepicker__navigation {
  border: 0.81rem solid transparent;
}

.react-datepicker__portal .react-datepicker__navigation--previous {
  border-right-color: #ccc;
}

.react-datepicker__portal .react-datepicker__navigation--previous:hover {
  border-right-color: #b3b3b3;
}

.react-datepicker__portal .react-datepicker__navigation--previous--disabled, .react-datepicker__portal .react-datepicker__navigation--previous--disabled:hover {
  border-right-color: #e6e6e6;
  cursor: default;
}

.react-datepicker__portal .react-datepicker__navigation--next {
  border-left-color: #ccc;
}

.react-datepicker__portal .react-datepicker__navigation--next:hover {
  border-left-color: #b3b3b3;
}

.react-datepicker__portal .react-datepicker__navigation--next--disabled, .react-datepicker__portal .react-datepicker__navigation--next--disabled:hover {
  border-left-color: #e6e6e6;
  cursor: default;
}

/**
 * Extra class applied to VirtualScroll through className prop
 */
.rst__virtualScrollOverride {
  overflow: auto !important;
}
.rst__virtualScrollOverride * {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.ReactVirtualized__Grid__innerScrollContainer {
  overflow: visible !important;
}

.rst__rtl .ReactVirtualized__Grid__innerScrollContainer {
  direction: rtl;
}

.ReactVirtualized__Grid {
  outline: none;
}

.rst__node {
  min-width: 100%;
  white-space: nowrap;
  position: relative;
  text-align: left;
}

.rst__node.rst__rtl {
  text-align: right;
}

.rst__nodeContent {
  position: absolute;
  top: 0;
  bottom: 0;
}

/* ==========================================================================
   Scaffold

    Line-overlaid blocks used for showing the tree structure
   ========================================================================== */
.rst__lineBlock,
.rst__absoluteLineBlock {
  height: 100%;
  position: relative;
  display: inline-block;
}

.rst__absoluteLineBlock {
  position: absolute;
  top: 0;
}

.rst__lineHalfHorizontalRight::before,
.rst__lineFullVertical::after,
.rst__lineHalfVerticalTop::after,
.rst__lineHalfVerticalBottom::after {
  position: absolute;
  content: '';
  background-color: black;
}

/**
 * +-----+
 * |     |
 * |  +--+
 * |     |
 * +-----+
 */
.rst__lineHalfHorizontalRight::before {
  height: 1px;
  top: 50%;
  right: 0;
  width: 50%;
}

.rst__rtl.rst__lineHalfHorizontalRight::before {
  left: 0;
  right: auto;
  right: initial;
}

/**
 * +--+--+
 * |  |  |
 * |  |  |
 * |  |  |
 * +--+--+
 */
.rst__lineFullVertical::after,
.rst__lineHalfVerticalTop::after,
.rst__lineHalfVerticalBottom::after {
  width: 1px;
  left: 50%;
  top: 0;
  height: 100%;
}

/**
 * +--+--+
 * |  |  |
 * |  |  |
 * |  |  |
 * +--+--+
 */
.rst__rtl.rst__lineFullVertical::after,
.rst__rtl.rst__lineHalfVerticalTop::after,
.rst__rtl.rst__lineHalfVerticalBottom::after {
  right: 50%;
  left: auto;
  left: initial;
}

/**
 * +-----+
 * |  |  |
 * |  +  |
 * |     |
 * +-----+
 */
.rst__lineHalfVerticalTop::after {
  height: 50%;
}

/**
 * +-----+
 * |     |
 * |  +  |
 * |  |  |
 * +-----+
 */
.rst__lineHalfVerticalBottom::after {
  top: auto;
  bottom: 0;
  height: 50%;
}

/* Highlight line for pointing to dragged row destination
   ========================================================================== */
/**
 * +--+--+
 * |  |  |
 * |  |  |
 * |  |  |
 * +--+--+
 */
.rst__highlightLineVertical {
  z-index: 3;
}
.rst__highlightLineVertical::before {
  position: absolute;
  content: '';
  background-color: #36c2f6;
  width: 8px;
  margin-left: -4px;
  left: 50%;
  top: 0;
  height: 100%;
}

.rst__rtl.rst__highlightLineVertical::before {
  margin-left: 0;
  margin-left: initial;
  margin-right: -4px;
  left: auto;
  left: initial;
  right: 50%;
}

@-webkit-keyframes arrow-pulse {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
    opacity: 0;
  }
  30% {
    -webkit-transform: translate(0, 300%);
            transform: translate(0, 300%);
    opacity: 1;
  }
  70% {
    -webkit-transform: translate(0, 700%);
            transform: translate(0, 700%);
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(0, 1000%);
            transform: translate(0, 1000%);
    opacity: 0;
  }
}

@keyframes arrow-pulse {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
    opacity: 0;
  }
  30% {
    -webkit-transform: translate(0, 300%);
            transform: translate(0, 300%);
    opacity: 1;
  }
  70% {
    -webkit-transform: translate(0, 700%);
            transform: translate(0, 700%);
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(0, 1000%);
            transform: translate(0, 1000%);
    opacity: 0;
  }
}
.rst__highlightLineVertical::after {
  content: '';
  position: absolute;
  height: 0;
  margin-left: -4px;
  left: 50%;
  top: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid white;
  -webkit-animation: arrow-pulse 1s infinite linear both;
          animation: arrow-pulse 1s infinite linear both;
}

.rst__rtl.rst__highlightLineVertical::after {
  margin-left: 0;
  margin-left: initial;
  margin-right: -4px;
  right: 50%;
  left: auto;
  left: initial;
}

/**
 * +-----+
 * |     |
 * |  +--+
 * |  |  |
 * +--+--+
 */
.rst__highlightTopLeftCorner::before {
  z-index: 3;
  content: '';
  position: absolute;
  border-top: solid 8px #36c2f6;
  border-left: solid 8px #36c2f6;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: calc(50% + 4px);
  top: 50%;
  margin-top: -4px;
  right: 0;
  width: calc(50% + 4px);
}

.rst__rtl.rst__highlightTopLeftCorner::before {
  border-right: solid 8px #36c2f6;
  border-left: none;
  left: 0;
  right: auto;
  right: initial;
}

/**
 * +--+--+
 * |  |  |
 * |  |  |
 * |  +->|
 * +-----+
 */
.rst__highlightBottomLeftCorner {
  z-index: 3;
}
.rst__highlightBottomLeftCorner::before {
  content: '';
  position: absolute;
  border-bottom: solid 8px #36c2f6;
  border-left: solid 8px #36c2f6;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: calc(100% + 4px);
  top: 0;
  right: 12px;
  width: calc(50% - 8px);
}

.rst__rtl.rst__highlightBottomLeftCorner::before {
  border-right: solid 8px #36c2f6;
  border-left: none;
  left: 12px;
  right: auto;
  right: initial;
}

.rst__highlightBottomLeftCorner::after {
  content: '';
  position: absolute;
  height: 0;
  right: 0;
  top: 100%;
  margin-top: -12px;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-left: 12px solid #36c2f6;
}

.rst__rtl.rst__highlightBottomLeftCorner::after {
  left: 0;
  right: auto;
  right: initial;
  border-right: 12px solid #36c2f6;
  border-left: none;
}

.rst__rowWrapper {
  padding: 10px 10px 10px 0;
  height: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.rst__rtl.rst__rowWrapper {
  padding: 10px 0 10px 10px;
}

.rst__row {
  height: 100%;
  white-space: nowrap;
  display: -ms-flexbox;
  display: flex;
}
.rst__row > * {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

/**
 * The outline of where the element will go if dropped, displayed while dragging
 */
.rst__rowLandingPad,
.rst__rowCancelPad {
  border: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  outline: none !important;
}
.rst__rowLandingPad > *,
.rst__rowCancelPad > * {
  opacity: 0 !important;
}
.rst__rowLandingPad::before,
.rst__rowCancelPad::before {
  background-color: lightblue;
  border: 3px dashed white;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

/**
 * Alternate appearance of the landing pad when the dragged location is invalid
 */
.rst__rowCancelPad::before {
  background-color: #e6a8ad;
}

/**
 * Nodes matching the search conditions are highlighted
 */
.rst__rowSearchMatch {
  outline: solid 3px #0080ff;
}

/**
 * The node that matches the search conditions and is currently focused
 */
.rst__rowSearchFocus {
  outline: solid 3px #fc6421;
}

.rst__rowContents,
.rst__rowLabel,
.rst__rowToolbar,
.rst__moveHandle,
.rst__toolbarButton {
  display: inline-block;
  vertical-align: middle;
}

.rst__rowContents {
  position: relative;
  height: 100%;
  border: solid #bbb 1px;
  border-left: none;
  -webkit-box-shadow: 0 2px 2px -2px;
          box-shadow: 0 2px 2px -2px;
  padding: 0 5px 0 10px;
  border-radius: 2px;
  min-width: 230px;
  -ms-flex: 1 0 auto;
      flex: 1 0 auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  background-color: white;
}

.rst__rtl.rst__rowContents {
  border-right: none;
  border-left: solid #bbb 1px;
  padding: 0 10px 0 5px;
}

.rst__rowContentsDragDisabled {
  border-left: solid #bbb 1px;
}

.rst__rtl.rst__rowContentsDragDisabled {
  border-right: solid #bbb 1px;
  border-left: solid #bbb 1px;
}

.rst__rowLabel {
  -ms-flex: 0 1 auto;
      flex: 0 1 auto;
  padding-right: 20px;
}
.rst__rtl.rst__rowLabel {
  padding-left: 20px;
  padding-right: inherit;
}

.rst__rowToolbar {
  -ms-flex: 0 1 auto;
      flex: 0 1 auto;
  display: -ms-flexbox;
  display: flex;
}

.rst__moveHandle,
.rst__loadingHandle {
  height: 100%;
  width: 44px;
  background: #d9d9d9
    url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MiIgaGVpZ2h0PSI0MiI+PGcgc3Ryb2tlPSIjRkZGIiBzdHJva2Utd2lkdGg9IjIuOSIgPjxwYXRoIGQ9Ik0xNCAxNS43aDE0LjQiLz48cGF0aCBkPSJNMTQgMjEuNGgxNC40Ii8+PHBhdGggZD0iTTE0IDI3LjFoMTQuNCIvPjwvZz4KPC9zdmc+')
    no-repeat center;
  border: solid #aaa 1px;
  -webkit-box-shadow: 0 2px 2px -2px;
          box-shadow: 0 2px 2px -2px;
  cursor: move;
  border-radius: 1px;
  z-index: 1;
}

.rst__loadingHandle {
  cursor: default;
  background: #d9d9d9;
}

@-webkit-keyframes pointFade {
  0%,
  19.999%,
  100% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
}

@keyframes pointFade {
  0%,
  19.999%,
  100% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
}

.rst__loadingCircle {
  width: 80%;
  height: 80%;
  margin: 10%;
  position: relative;
}

.rst__loadingCirclePoint {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.rst__rtl.rst__loadingCirclePoint {
  right: 0;
  left: auto;
  left: initial;
}

.rst__loadingCirclePoint::before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 11%;
  height: 30%;
  background-color: #fff;
  border-radius: 30%;
  -webkit-animation: pointFade 800ms infinite ease-in-out both;
          animation: pointFade 800ms infinite ease-in-out both;
}
.rst__loadingCirclePoint:nth-of-type(1) {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.rst__loadingCirclePoint:nth-of-type(7) {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.rst__loadingCirclePoint:nth-of-type(1)::before,
.rst__loadingCirclePoint:nth-of-type(7)::before {
  -webkit-animation-delay: -800ms;
          animation-delay: -800ms;
}
.rst__loadingCirclePoint:nth-of-type(2) {
  -webkit-transform: rotate(30deg);
          transform: rotate(30deg);
}
.rst__loadingCirclePoint:nth-of-type(8) {
  -webkit-transform: rotate(210deg);
          transform: rotate(210deg);
}
.rst__loadingCirclePoint:nth-of-type(2)::before,
.rst__loadingCirclePoint:nth-of-type(8)::before {
  -webkit-animation-delay: -666ms;
          animation-delay: -666ms;
}
.rst__loadingCirclePoint:nth-of-type(3) {
  -webkit-transform: rotate(60deg);
          transform: rotate(60deg);
}
.rst__loadingCirclePoint:nth-of-type(9) {
  -webkit-transform: rotate(240deg);
          transform: rotate(240deg);
}
.rst__loadingCirclePoint:nth-of-type(3)::before,
.rst__loadingCirclePoint:nth-of-type(9)::before {
  -webkit-animation-delay: -533ms;
          animation-delay: -533ms;
}
.rst__loadingCirclePoint:nth-of-type(4) {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.rst__loadingCirclePoint:nth-of-type(10) {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}
.rst__loadingCirclePoint:nth-of-type(4)::before,
.rst__loadingCirclePoint:nth-of-type(10)::before {
  -webkit-animation-delay: -400ms;
          animation-delay: -400ms;
}
.rst__loadingCirclePoint:nth-of-type(5) {
  -webkit-transform: rotate(120deg);
          transform: rotate(120deg);
}
.rst__loadingCirclePoint:nth-of-type(11) {
  -webkit-transform: rotate(300deg);
          transform: rotate(300deg);
}
.rst__loadingCirclePoint:nth-of-type(5)::before,
.rst__loadingCirclePoint:nth-of-type(11)::before {
  -webkit-animation-delay: -266ms;
          animation-delay: -266ms;
}
.rst__loadingCirclePoint:nth-of-type(6) {
  -webkit-transform: rotate(150deg);
          transform: rotate(150deg);
}
.rst__loadingCirclePoint:nth-of-type(12) {
  -webkit-transform: rotate(330deg);
          transform: rotate(330deg);
}
.rst__loadingCirclePoint:nth-of-type(6)::before,
.rst__loadingCirclePoint:nth-of-type(12)::before {
  -webkit-animation-delay: -133ms;
          animation-delay: -133ms;
}
.rst__loadingCirclePoint:nth-of-type(7) {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.rst__loadingCirclePoint:nth-of-type(13) {
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
}
.rst__loadingCirclePoint:nth-of-type(7)::before,
.rst__loadingCirclePoint:nth-of-type(13)::before {
  -webkit-animation-delay: 0ms;
          animation-delay: 0ms;
}

.rst__rowTitle {
  font-weight: bold;
}

.rst__rowTitleWithSubtitle {
  font-size: 85%;
  display: block;
  height: 0.8rem;
}

.rst__rowSubtitle {
  font-size: 70%;
  line-height: 1;
}

.rst__collapseButton,
.rst__expandButton {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  position: absolute;
  border-radius: 100%;
  -webkit-box-shadow: 0 0 0 1px #000;
          box-shadow: 0 0 0 1px #000;
  width: 16px;
  height: 16px;
  padding: 0;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  cursor: pointer;
}
.rst__rtl.rst__collapseButton,
.rst__rtl.rst__expandButton {
  -webkit-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
}
.rst__collapseButton:focus,
.rst__expandButton:focus {
  outline: none;
  -webkit-box-shadow: 0 0 0 1px #000, 0 0 1px 3px #83bef9;
          box-shadow: 0 0 0 1px #000, 0 0 1px 3px #83bef9;
}
.rst__collapseButton:hover:not(:active),
.rst__expandButton:hover:not(:active) {
  background-size: 24px;
  height: 20px;
  width: 20px;
}

.rst__collapseButton {
  background: #fff
    url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCI+PGNpcmNsZSBjeD0iOSIgY3k9IjkiIHI9IjgiIGZpbGw9IiNGRkYiLz48ZyBzdHJva2U9IiM5ODk4OTgiIHN0cm9rZS13aWR0aD0iMS45IiA+PHBhdGggZD0iTTQuNSA5aDkiLz48L2c+Cjwvc3ZnPg==')
    no-repeat center;
}

.rst__expandButton {
  background: #fff
    url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCI+PGNpcmNsZSBjeD0iOSIgY3k9IjkiIHI9IjgiIGZpbGw9IiNGRkYiLz48ZyBzdHJva2U9IiM5ODk4OTgiIHN0cm9rZS13aWR0aD0iMS45IiA+PHBhdGggZD0iTTQuNSA5aDkiLz48cGF0aCBkPSJNOSA0LjV2OSIvPjwvZz4KPC9zdmc+')
    no-repeat center;
}

/**
 * Line for under a node with children
 */
.rst__lineChildren {
  height: 100%;
  display: inline-block;
  position: absolute;
}
.rst__lineChildren::after {
  content: '';
  position: absolute;
  background-color: black;
  width: 1px;
  left: 50%;
  bottom: 0;
  height: 10px;
}

.rst__rtl.rst__lineChildren::after {
  right: 50%;
  left: auto;
  left: initial;
}

.rst__placeholder {
  position: relative;
  height: 68px;
  max-width: 300px;
  padding: 10px;
}
.rst__placeholder,
.rst__placeholder > * {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.rst__placeholder::before {
  border: 3px dashed #d9d9d9;
  content: '';
  position: absolute;
  top: 5px;
  right: 5px;
  bottom: 5px;
  left: 5px;
  z-index: -1;
}

/**
 * The outline of where the element will go if dropped, displayed while dragging
 */
.rst__placeholderLandingPad,
.rst__placeholderCancelPad {
  border: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  outline: none !important;
}
.rst__placeholderLandingPad *,
.rst__placeholderCancelPad * {
  opacity: 0 !important;
}
.rst__placeholderLandingPad::before,
.rst__placeholderCancelPad::before {
  background-color: lightblue;
  border-color: white;
}

/**
 * Alternate appearance of the landing pad when the dragged location is invalid
 */
.rst__placeholderCancelPad::before {
  background-color: #e6a8ad;
}

/* Collection default theme */

.ReactVirtualized__Collection {
}

.ReactVirtualized__Collection__innerScrollContainer {
}

/* Grid default theme */

.ReactVirtualized__Grid {
}

.ReactVirtualized__Grid__innerScrollContainer {
}

/* Table default theme */

.ReactVirtualized__Table {
}

.ReactVirtualized__Table__Grid {
}

.ReactVirtualized__Table__headerRow {
  font-weight: 700;
  text-transform: uppercase;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: center;
      align-items: center;
}
.ReactVirtualized__Table__row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: center;
      align-items: center;
}

.ReactVirtualized__Table__headerTruncatedText {
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ReactVirtualized__Table__headerColumn,
.ReactVirtualized__Table__rowColumn {
  margin-right: 10px;
  min-width: 0px;
}
.ReactVirtualized__Table__rowColumn {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ReactVirtualized__Table__headerColumn:first-of-type,
.ReactVirtualized__Table__rowColumn:first-of-type {
  margin-left: 10px;
}
.ReactVirtualized__Table__sortableHeaderColumn {
  cursor: pointer;
}

.ReactVirtualized__Table__sortableHeaderIconContainer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}
.ReactVirtualized__Table__sortableHeaderIcon {
  -ms-flex: 0 0 24px;
      flex: 0 0 24px;
  height: 1em;
  width: 1em;
  fill: currentColor;
}

/* List default theme */

.ReactVirtualized__List {
}
